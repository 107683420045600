import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import "./styles.css";
import EmailIcon from "../../images/homepage/emailIcon.png";
import LocationIcon from "../../images/homepage/locationIcon.png";
import LinkedInIcon from "../../images/linkedin.svg";
import ResumeIcon from "../../images/resumeIcon.png";
import RejectPopup from "../../components/RejectPopup";
import HiredPopup from "../../components/HiredPopup";
import downArrow from "../../images/down-arrow.svg";
import UploadCandidatesModal from "../../components/UploadCandidates";
import InterviewWNoraPopup from "../../components/InterviewWNoraPopup";
import DownloadIcon from "../../images/downloadCandidates.png";
import downloadCandidatesAsCSV from "../../components/downloadCandidatesAsCSV";

function ApplicantListNew() {
  const { companyInterviewId } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Access the passed data
  const { companyName, position, countCandidateToInterviews } = location.state || {};
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [popupApplicant, setPopupApplicant] = useState(null);
  const [popupHiredApplicant, setPopupHiredApplicant] = useState(null);
  const [popupInterviewApplicant, setPopupInterviewApplicant] = useState(null);
  const [loadingApplicantId, setLoadingApplicantId] = useState(null);
  const [emailSentApplicantId, setEmailSentApplicantId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [scoreSortOrder, setScoreSortOrder] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const getCandidatesToInterviewEndpoint = process.env.REACT_APP_GET_CANDIDATES_TO_INTERVIEW;

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await fetch(
          `${getCandidatesToInterviewEndpoint}?companyInterviewId=${companyInterviewId}`
        );
        const data = await response.json();
        setApplicants(data);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch applicants:", err);
      }
    };

    fetchApplicants();
  }, [companyInterviewId]);

  const handleFileUpload = (files) => {
    console.log("Uploaded files:", files);
    // Add logic to process the uploaded files (e.g., API call to upload)
  };

  const handleDownloadCandidates = async () => {
    setIsDownloading(true); // Show loader and update button text
    try {
      await downloadCandidatesAsCSV(applicants); // Call the download function
    } catch (error) {
      console.error("Error downloading candidates:", error);
    } finally {
      setIsDownloading(false); // Reset loader state
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const sortApplicantsByDate = () => {
    const sortedApplicants = [...applicants].sort((a, b) => {
      const dateA = new Date(a.appliedDate);
      const dateB = new Date(b.appliedDate);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  
    setApplicants(sortedApplicants);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); 
  };

  const sortApplicantsByScore = () => {
    const sortedApplicants = [...applicants].sort((a, b) => {
      const scoreA = a.interviewResults
        ? parseInt(JSON.parse(a.interviewResults).overallScore) || 0
        : -1;
      const scoreB = b.interviewResults
        ? parseInt(JSON.parse(b.interviewResults).overallScore) || 0
        : -1;
  
      return scoreSortOrder === "asc" ? scoreA - scoreB : scoreB - scoreA;
    });
  
    setApplicants(sortedApplicants);
    setScoreSortOrder(scoreSortOrder === "asc" ? "desc" : "asc"); 
  };

  const getStatus = (applicant) => {
    if (applicant.rejected) {
      return "Rejected";
    } 
    if (applicant.hired) {
      return "Hired";
    }
    if (applicant.interviewWithNora === null) {
      return "Submitted";
    }
    if (applicant.interviewWithNora === true && applicant.interviewResults === null) {
      return "Awaiting";
    }
    if (applicant.interviewWithNora === true && applicant.interviewResults !== null) {
      return "Interviewed";
    }
    return "N/A"; // Fallback for unexpected cases
  };

  return (
    <>
      <div>
      <Navbar showSidebar={false} />
      <div className="dashboard-container">
        <article className="glass-container">
            <button className="back-button-applicantList" onClick={() => navigate(-1)}>
          &lt; Back
        </button>
          <div className="flex-row-container-header2">
            <h1 className="title">{position}</h1>

            <div className="button-container">
              <button
                className="download-candidates-button"
                onClick={handleDownloadCandidates}
                disabled={isDownloading}>
                {isDownloading ? (
                    <>
                      <span className="loader"></span> Downloading Candidates...
                    </>
                  ) : (
                    <>
                      Download Candidates
                      <img src={DownloadIcon} alt="Download Icon" />
                    </>
                  )}
              </button>

              <button
                className="dashboard-add-job-button4"
                onClick={() => setIsModalOpen(true)}>
                + Upload Candidate
              </button>
            </div>
          </div>

          <h3 className="sub-title">{countCandidateToInterviews} Applicants </h3>
          <div className="dashboard-internship-title-container2">
            <div className="dashboard-internship-info-title" style={{ marginLeft: "10px", cursor: "pointer" }} 
              onClick={sortApplicantsByDate}> Date {sortOrder === "asc" ? "↑" : "↓"}
            </div>
            <div className="dashboard-internship-info-title">Name</div>
            <div className="dashboard-internship-info-title">Status</div>
            <div className="dashboard-internship-info-title">Interview with Nora?</div>
            <div className="dashboard-internship-info-title" style={{ cursor: "pointer" }} onClick={sortApplicantsByScore}>
              Interview Results {scoreSortOrder === "asc" ? "↑" : scoreSortOrder === "desc" ? "↓" : "↑"}
            </div>
          </div>

          <hr className="dashboard-divider-line" />

          <div className="dashboard-internships-info-container">
            {Array.isArray(applicants) ? (
                applicants.map((applicant) => (
                <React.Fragment key={applicant.id}>
                <div className="dashboard-internship-row" onClick={() => {
                    setSelectedApplicant(applicant === selectedApplicant ? null : applicant);
                }}>

                    <div className="dashboard-internship-info">
                    {new Date(applicant.appliedDate).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                    })}
                    </div>
                    
                    <div className="dashboard-internship-info">{applicant.name}</div>

                    <div className="dashboard-internship-info">
                        <span className={`status`}>
                            {getStatus(applicant)}
                        </span>
                    </div>

                    <div className="dashboard-internship-info">
                      {loadingApplicantId === applicant.candidateToInterviewId ? (
                        <div className="loaderForInterviewSent"></div> 
                      ) : emailSentApplicantId === applicant.candidateToInterviewId ? (
                        <div className="custom-email-sent">
                          <span>Email Sent</span>
                        </div>
                      ) : (
                        <input
                          type="checkbox"
                          checked={!!applicant.interviewWithNora}
                          onClick={(e) => e.stopPropagation()} 
                          onChange={() => setPopupInterviewApplicant(applicant)} 
                        />
                      )}
                    </div>

                    <div className="dashboard-internship-info">
                      {applicant.interviewResults ? (
                        String(JSON.parse(applicant.interviewResults).overallScore).toLowerCase().includes("minimal") ||
                        String(JSON.parse(applicant.interviewResults).overallScore).toLowerCase().includes("insufficient") ? (
                          <span className="status">View Results</span>
                        ) : (
                          <span className="status">
                            View Results - {JSON.parse(applicant.interviewResults).overallScore}
                          </span>
                        )
                      ) : (
                        <span className="status">N/A</span>
                      )}
                    </div>
                </div>

                {selectedApplicant === applicant && (
                <div className="inline-modal">
                    <div className="modal-content2">
                        <button className="close-button" onClick={() => setSelectedApplicant(null)}>
                            &times;
                        </button>

                        <div className="modal-grid">
                            {/* Left Column */}
                            <div className="left-column">
                                <div className="date-text">
                                    {new Date(applicant.appliedDate).toLocaleDateString("en-US", {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                    })}
                                </div>

                                <h3>{selectedApplicant.name}</h3>

                                <div className="location-status" style={{ marginTop: "-23px" }}>
                                    <img
                                        src={LocationIcon}
                                        alt="Location Icon"
                                        className="location-icon"
                                    />
                                    <p style={{ marginTop: "10px" }}>
                                        {selectedApplicant.location}
                                    </p>
                                </div>

                                <div className="location-status" style={{ marginTop: "10px" }}>
                                    <img
                                        src={EmailIcon}
                                        alt="Email Icon"
                                        className="location-icon"
                                    />
                                    <p style={{ marginTop: "10px" }}>
                                        {selectedApplicant.email}
                                    </p>
                                </div>

                                {selectedApplicant.linkedIn && !selectedApplicant.linkedIn.toLowerCase().includes("not provided") ? (
                                  <div className="location-status" style={{ marginTop: "-25px" }}>
                                      <img
                                          src={LinkedInIcon}
                                          alt="LinkedIn Icon"
                                          className="location-icon"
                                      />
                                      <p style={{ marginTop: "10px" }}>
                                          <a href={selectedApplicant.linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                                      </p>
                                  </div>
                                ) : null}

                                {selectedApplicant.resumeFileName ? (
                                    <div className="location-status" style={{ marginTop: "-25px" }}>
                                        <img
                                            src={ResumeIcon}
                                            alt="Resume Icon"
                                            className="location-icon"
                                        />
                                        <p style={{ marginTop: "10px" }}>
                                            <a href={selectedApplicant.resumeFileName} target="_blank" rel="noopener noreferrer">Resume</a>
                                        </p>
                                    </div>
                                ) : null}

                                <div className="hire-reject-buttons">
                                    <button className="hire-button" onClick={() => setPopupHiredApplicant(applicant)}>
                                        Hire
                                    </button>
                                    <button className="reject-button" onClick={() => setPopupApplicant(applicant)}>
                                        Reject
                                    </button>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="right-column">
                                {selectedApplicant.interviewResults ? (
                                    <>
                                        <p><strong>Interview Results</strong></p>
                                        <p><strong>Overall Score:</strong> {JSON.parse(selectedApplicant.interviewResults).overallScore}</p>
                                        <p><strong>Overall Feedback:</strong> {JSON.parse(selectedApplicant.interviewResults).overallFeedback}</p>
                                        <p><strong>Cultural Fit Feedback:</strong> {JSON.parse(selectedApplicant.interviewResults).culturalFitFeedback}</p>
                                        <p><strong>Technical Skill Feedback:</strong> {JSON.parse(selectedApplicant.interviewResults).technicalSkillFeedback}</p>
                                        <p><strong>Soft Skill Feedback:</strong> {JSON.parse(selectedApplicant.interviewResults).softSkillFeedback}</p>
                                        <p><strong>Must Have Feedback:</strong> {JSON.parse(selectedApplicant.interviewResults).mustHaveSummary}</p>

                                        {selectedApplicant.videoFileName && (
                                          <>
                                          <div style={{ margin: "3% 0" }}>
                                            <p><strong>Audio Recording: </strong></p>
                                            <mux-player
                                              stream-type="on-demand"
                                              playback-id={selectedApplicant.videoFileName}
                                              metadata-video-title="Audio Playback"
                                              audio
                                            ></mux-player>
                                          </div>
                                          </>
                                        )}

                                        {JSON.parse(selectedApplicant.interviewResults).questionAnalysis ? (
                                          <>
                                            <p><strong>Question By Question Analysis: </strong></p>
                                            <div className="accordion-container-qbqAnalysis">
                                              {JSON.parse(selectedApplicant.interviewResults).questionAnalysis.map((qa, index) => (
                                                <div key={index} className="accordion-item-qbqAnalysis" style={{ marginBottom: "1rem", borderBottom: "1px solid #ddd", paddingBottom: "1rem" }}>
                                                  <h2 className="accordion-header-qbqAnalysis">
                                                    <button
                                                      className="accordion-button-qbqAnalysis collapsed"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#collapse-${index}`}
                                                      aria-expanded="false"
                                                      aria-controls={`collapse-${index}`}
                                                    >
                                                      <p className="qa-question-text-qbqAnalysis">{qa.question}</p>
                                                      <img src={downArrow} alt="Toggle" className="qa-toggle-icon-qbqAnalysis" />
                                                    </button>
                                                  </h2>
                                                  <div
                                                    id={`collapse-${index}`}
                                                    className="accordion-collapse-qbqAnalysis collapse"
                                                    aria-labelledby={`heading-${index}`}
                                                  >
                                                    <div className="accordion-body-qbqAnalysis">
                                                      <p><strong>Candidate Answer:</strong> {qa.candidateAnswer}</p>
                                                      <p><strong>Nora's Analysis:</strong> {qa.analysis}</p>
                                                      <p><strong>Nora's Score:</strong> {qa.candidateScore}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </>
                                        ) : (
                                          <p>No question-by-question analysis available!</p>
                                        )}

                                        <p><strong>Transcript:</strong></p>
                                        <div>
                                          {selectedApplicant &&
                                          selectedApplicant.interviewResults &&
                                          JSON.parse(selectedApplicant.interviewResults).transcript ? (
                                            JSON.parse(selectedApplicant.interviewResults).transcript
                                              .split(/(?=User:|Assistant:)/)
                                              .filter(line => !line.trim().startsWith("User: first"))
                                              .map((line, index) => {
                                                const cleanedLine = line.trim();

                                                if (cleanedLine.startsWith("User:")) {
                                                  return (
                                                    <p key={index}>
                                                      <strong>{selectedApplicant.name}:</strong>{" "}
                                                      {cleanedLine.replace("User:", "").trim()}
                                                    </p>
                                                  );
                                                } else if (cleanedLine.startsWith("Assistant:")) {
                                                  return (
                                                    <p key={index}>
                                                      <strong>Nora:</strong> {cleanedLine.replace("Assistant:", "").trim()}
                                                    </p>
                                                  );
                                                } else {
                                                  return <p key={index}>{cleanedLine}</p>; // Handle unexpected lines
                                                }
                                              })
                                          ) : (
                                            <p>No transcript available</p>
                                          )}
                                        </div>
                                    </>
                                ) : (
                                    <p>No Nora Interview Result</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                )}
                </React.Fragment>
              ))) : (
                <p>No applicants available.</p>
              )}
          </div>
        </article>
      </div>
    </div>

    {popupHiredApplicant && (
        <HiredPopup
            isPopupVisible={!!popupHiredApplicant} // Pass visibility state
            onClose={() => setPopupHiredApplicant(null)} // Close the popup
            candidateToInterviewId={popupHiredApplicant.candidateToInterviewId}
            candidateEmail={popupHiredApplicant.email}
            candidateName={popupHiredApplicant.name}
            position={position}
            companyName={companyName}
        />
    )}

    {popupInterviewApplicant && (
      <InterviewWNoraPopup
        isPopupVisible={!!popupInterviewApplicant} // Pass visibility state
        onClose={() => setPopupInterviewApplicant(null)} // Close the popup
        candidateToInterviewId={popupInterviewApplicant.candidateToInterviewId}
        candidateEmail={popupInterviewApplicant.email}
        candidateName={popupInterviewApplicant.name}
        interviewWithNora={popupInterviewApplicant.interviewWithNora}
        position={position}
        companyName={companyName}
        interviewURL={popupInterviewApplicant.interviewURL} // Pass interview URL dynamically
        setLoadingApplicantId={setLoadingApplicantId} // Pass state function
        setApplicants={setApplicants}               // Pass state function
        setEmailSentApplicantId={setEmailSentApplicantId}
      />
    )}

    {popupApplicant && (
        <RejectPopup
            isPopupVisible={!!popupApplicant} // Pass visibility state
            onClose={() => setPopupApplicant(null)} // Close the popup
            candidateToInterviewId={popupApplicant.candidateToInterviewId}
            candidateEmail={popupApplicant.email}
            candidateName={popupApplicant.name}
            position={position}
            companyName={companyName}
        />
    )}

    <UploadCandidatesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal
        onUpload={handleFileUpload} // Handle file upload
        companyInterviewId={companyInterviewId}
      />
    </>
  );
}

export default ApplicantListNew;