import React from "react";
import "./style.css"; 
import editButton from "../../images/editButton.png";

const ReviewPosting = ({
    jobTitle,
    companyName,
    locationForm,
    jobType,
    description,
    generatedScript,
    onBack,
    onSubmit,
    onEditJobPosting,
    onEditInterviewQuestions,
  }) => {
  
    return (
        <div className="review-page">
          <h1>Nora's Interview Script Preview </h1>
          <p className="subheading">
            Review the interview and job description details to ensure everything is accurate.
          </p>
          
          <div className="job-posting">
            <div className="edit-icon-container">
              <img 
                src={editButton} 
                onClick={onEditJobPosting}
                alt="Edit" 
                className="edit-icon" 
                title="Edit Job Posting"
              />
            </div>
            <h2>Job Posting</h2>

            <p>
              <strong>Job title:</strong> {jobTitle}
            </p>
            <p>
              <strong>Company Name:</strong> {companyName}
            </p>
            <p>
              <strong>Location:</strong> {locationForm}
            </p>
            <p>
                <strong>Type:</strong> {jobType}
            </p>
            <p>
                <strong>Job description:</strong>
            </p>
            <div className="job-description-content" dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
    
          <div className="interview-questions">
            <div className="edit-icon-container">
              <img 
                src={editButton} 
                onClick={onEditInterviewQuestions}
                alt="Edit" 
                className="edit-icon" 
                title="Edit Job Posting"
              />
            </div>
            <h2 style={{ marginBottom: "30px" }}>Interview Questions</h2>
            {generatedScript.buckets.map((bucket, index) => (
            <div key={index} className="bucket">
                <h3> {index + 1}. {bucket.name} </h3>
                {bucket.examples.map((example, i) => (
                <p key={i} className="example2">
                    Example {example}
                </p>
                ))}
            </div>
            ))}

            {generatedScript.questions.map((question, i) => (
            <p key={i} className="general-question">
                {generatedScript.buckets.length + i + 1}. {question}
            </p>
            ))}
          </div>
    
          <div className="actions">
            <button className="post-position-button" onClick={onBack}>
              Back
            </button>
            <button className="post-position-button" onClick={onSubmit}>
              + Add Job Posting
            </button>
          </div>
        </div>
    );
};

export default ReviewPosting;